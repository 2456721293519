<template>
  <div class="card border-0 radius-bottom-0" v-if="physical_person">
    <div class="card-header bg-dark text-white row m-0 pt-4 pb-4">
      <div class="col-11">
        <h4 class="card-tite d-flex align-items-center">
          <font-awesome-icon icon="user" class="display-4 mr-3" />

          <span class="">
            <label class="form-label">Pessoa Física</label><br />
            {{ physical_person.name }}
          </span>
        </h4>
      </div>

      <div class="col-1 d-flex align-items-end">
        <router-link
          v-if="$can('physical_people:edit')"
          :to="{
            name: 'physical_people_edit',
            params: { id: physical_person.id }
          }"
          class="btn btn-outline-primary mr-2 link-edit"
          >Editar</router-link
        >

        <router-link
          v-else
          :to="{
            name: 'physical_people_show',
            params: { id: physical_person.id }
          }"
          class="btn btn-outline-primary mr-2 link-edit d-none"
          >Visualizar</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("physical_people", ["physical_person"])
  },
  methods: {},
  async created() {}
};
</script>
